import { theme } from "@Wills/styles/theme";
import styled from "styled-components";
import { Down, Up } from "@icon-park/react";

export const HeaderTextContainer = styled.div`
  margin: 0 auto;
  & > div {
    width: fit-content;
    margin-top: 0.5em;
  }
  & > p {
    text-align: left;
  }
  & > h2 {
    margin-bottom: 1rem;
  }
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  & > h2 {
    margin-bottom: 2em;
  }
`;

export const StyledTotalZakat = styled.div`
  width: max-content;
  text-align: center;
  background-color: #b2d5ac;
  border: solid darkgreen 2px;
  border-radius: 5px;
  padding: 0.5em;

  @media (min-width: 900px) {
    padding: 1em;
  }
`;

export const StyledBreakdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em 4em;
  justify-content: space-between;
  & > div {
    width: max-content;
    text-align: center;
  }
  & > div > h2 {
    margin-top: 0.75em;
  }

  @media (min-width: 900px) {
    justify-content: space-around;
    gap: 1em 8em;
  }
  @media (max-width: 900px) {
    justify-content: space-around;
    gap: 1em 8em;
  }
  @media (max-width: 450px) {
    justify-content: center;
  }
`;

export const Header = styled.h1`
  width: 100%;
  font-weight: bold;
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;
`;

export const StyledBoldText = styled.h2`
  font-weight: 700;
  font-size: medium;
  color: ${theme.colors.BrandNavy};
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > button {
    margin-top: 2em;
    max-width: 300px;
  }
`;

export const ErrorContainer = styled.div``;

export const BreakdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2em;
`;

export const BreakdownText = styled.div`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;
  width: fit-content;
  color: ${theme.colors.Primary01};
`;

export const StyledUp = styled(Up)`
  cursor: pointer;
`;

export const StyledDown = styled(Down)`
  cursor: pointer;
`;
